import React from 'react';
import {
  Added,
  Fixed,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from 'docComponents';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="December 2018"
      subnav="release-notes">
      <div id="December2018" className={styles.release}>
        <Paragraph className="uni-margin--one--vert">
          It’s been a while since we published release notes this early in the
          month, but these very special gifts are worth sharing before everyone
          takes off for the holidays: Not only are x-small buttons and form
          fields now a thing, but you also have the option to (re)create{' '}
          <strong>block buttons</strong>.
        </Paragraph>
        <Paragraph>
          And if those additions somehow fail to satisfy your need for new, we
          worked a couple of fixes into selects and the Sketch Library, as well.
        </Paragraph>
        <Paragraph>Merry Christmas, ya filthy animals.</Paragraph>

        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>
                Guidelines for the <em>x-small</em>{' '}
                <Link href="/components/buttons/button/design">button</Link> and{' '}
                <Link href="/components/forms/form-modifier/design">
                  form field
                </Link>
                .
              </Added>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="3.24.0"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v3.25.0"
            />

            <div className={styles.changesList}>
              <Added>
                The <code>isBlock</code> prop for{' '}
                <Link href="/components/buttons/button/code">buttons</Link>.
                (More to prevent an uprising than anything.)
              </Added>
              <Added>
                Support for disabled items in a{' '}
                <Link href="/components/selects/code">select</Link>.
              </Added>
              <Fixed>
                The{' '}
                <Link href="/components/selects/lookup-multi-select/code">
                  lookup multi-select
                </Link>{' '}
                to do its thing anytime strings are passed as values.
              </Fixed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="CSS"
              version="1.14.0"
              versionUrl="https://github.com/hudl/uniform-ui-css/releases/tag/v1.14.0"
            />

            <div className={styles.changesList}>
              <Added>Support for those x-small buttons and form fields.</Added>
              <Added>Block button support to boot.</Added>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Sketch Library" />

            <div className={styles.changesList}>
              <Added>The x-small things.</Added>
              <Fixed>Icon colors for the action bar and action list.</Fixed>
            </div>
          </div>
        </div>
        <Paragraph />
        <Paragraph>
          <Link href="https://media1.giphy.com/media/xUySTMO8XWIRjp0w4o/giphy.gif?cid=3640f6095c13e8f34d7131772ea5c440">
            And a happy new year
          </Link>
          .
        </Paragraph>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
